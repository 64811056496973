define("ember-cli-fake-server/lib/json-utils", ["exports", "ember-cli-fake-server/lib/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jsonFromRequest = jsonFromRequest;
  _exports.stringifyJSON = stringifyJSON;
  function stringifyJSON() {
    var json = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return JSON.stringify(json);
  }
  function jsonFromRequest(request) {
    var json = {};
    if (request.requestBody) {
      try {
        json = JSON.parse(request.requestBody);
      } catch (e) {
        (0, _logger.warn)("[FakeServer] Failed to parse json from request.requestBody \"".concat(request.requestBody, "\" (error: ").concat(e, ")"));
      }
    }
    return json;
  }
});