define("ember-cli-fake-server/lib/logger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.error = error;
  _exports.log = log;
  _exports.warn = warn;
  /* eslint no-console:0 */

  function warn() {
    if (typeof console !== 'undefined') {
      var _console;
      (_console = console).warn.apply(_console, arguments);
    }
  }
  function error() {
    if (typeof console !== 'undefined') {
      var _console2;
      (_console2 = console).error.apply(_console2, arguments);
    }
  }
  function log() {
    if (typeof console !== 'undefined') {
      var _console3;
      (_console3 = console).log.apply(_console3, arguments);
    }
  }
});