define("ember-cli-fake-server/index", ["exports", "pretender", "ember-cli-fake-server/lib/responses", "ember-cli-fake-server/lib/logging", "ember-cli-fake-server/lib/json-utils", "ember-cli-fake-server/lib/logger"], function (_exports, _pretender, Responses, Logging, JSONUtils, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.passthroughRequest = passthroughRequest;
  _exports.setupFakeServer = setupFakeServer;
  _exports.stubRequest = stubRequest;
  var currentServer;
  var _config = defaultConfig();
  function defaultConfig() {
    return {
      preparePath: function preparePath(path) {
        return path;
      },
      fixtureFactory: function fixtureFactory() {
        (0, _logger.warn)('[FakeServer] `fixture` called but no fixture factory is registered');
      },
      afterResponse: function afterResponse(response) {
        return response;
      }
    };
  }
  function bindResponses(request, responseRef) {
    Object.keys(Responses.STATUS_CODES).forEach(function (key) {
      request[key] = function () {
        if (responseRef.response) {
          throw new Error("[FakeServer] Stubbed Request responded with \"".concat(key, "\" after already responding"));
        }
        var response = Responses[key].apply(Responses, arguments);
        responseRef.response = response;
        return response;
      };
    });
    Object.keys(Responses.RESPONSE_ALIASES).forEach(function (key) {
      var aliases = Responses.RESPONSE_ALIASES[key];
      aliases.forEach(function (alias) {
        return request[alias] = request[key];
      });
    });
  }
  function setupFakeServer(hooks) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    hooks.beforeEach(function () {
      FakeServer.start(options);
    });
    hooks.afterEach(function () {
      FakeServer.stop();
    });
  }
  function passthroughRequest(verb, path) {
    path = _config.preparePath(path);
    (true && !(!!currentServer) && Ember.assert('[FakeServer] cannot passthrough request if FakeServer is not running', !!currentServer));
    currentServer[verb.toLowerCase()](path, currentServer.passthrough);
  }
  function stubRequest(verb, path, callback) {
    path = _config.preparePath(path);
    (true && !(!!currentServer) && Ember.assert('[FakeServer] cannot stub request if FakeServer is not running', !!currentServer));
    var boundCallback = function boundCallback(request) {
      var responseRef = {};
      bindResponses(request, responseRef);
      request.json = function () {
        return JSONUtils.jsonFromRequest(request);
      };
      request.fixture = _config.fixtureFactory;
      var context = {
        json: JSONUtils.jsonFromRequest,
        fixture: _config.fixtureFactory
      };
      Object.keys(Responses.STATUS_CODES).forEach(function (key) {
        context[key] = Responses[key];
      });
      Object.keys(Responses.RESPONSE_ALIASES).forEach(function (key) {
        var aliases = Responses.RESPONSE_ALIASES[key];
        aliases.forEach(function (alias) {
          return context[alias] = context[key];
        });
      });
      var returnValue = callback.call(context, request);
      returnValue = returnValue || responseRef.response;
      if (!returnValue) {
        throw new Error("[FakeServer] No return value for stubbed request for ".concat(verb, " ").concat(path, ".\n         Use `request.ok(json)` or similar"));
      }
      return _config.afterResponse(returnValue, request);
    };
    currentServer[verb.toLowerCase()](path, boundCallback);
  }
  var FakeServer = {
    configure: {
      fixtureFactory: function fixtureFactory(_fixtureFactory) {
        _config.fixtureFactory = _fixtureFactory;
      },
      preparePath: function preparePath(fn) {
        _config.preparePath = fn;
      },
      afterResponse: function afterResponse(fn) {
        _config.afterResponse = fn;
      }
    },
    start: function start() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (true && !(!FakeServer.isRunning()) && Ember.assert('[FakeServer] Cannot start FakeServer while already started. ' + 'Ensure you call `FakeServer.stop()` first.', !FakeServer.isRunning()));
      var logging = typeof options.logging === 'undefined' ? true : options.logging;
      currentServer = this._currentServer = new _pretender.default();
      currentServer.prepareBody = JSONUtils.stringifyJSON;
      currentServer.unhandledRequest = Logging.unhandledRequest;
      if (logging) {
        currentServer.handledRequest = Logging.handledRequest;
      }
    },
    isRunning: function isRunning() {
      return !!currentServer;
    },
    stop: function stop() {
      if (!FakeServer.isRunning()) {
        (0, _logger.warn)('[FakeServer] called `stop` without having started.');
        return;
      }
      currentServer.shutdown();
      currentServer = this._currentServer = null;
      _config = defaultConfig();
    }
  };
  var _default = _exports.default = FakeServer;
});