define("ember-cli-fake-server/lib/responses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUS_CODES = _exports.RESPONSE_ALIASES = void 0;
  _exports.accepted = accepted;
  _exports.created = created;
  _exports.error = error;
  _exports.noContent = noContent;
  _exports.notFound = notFound;
  _exports.ok = ok;
  _exports.unauthorized = unauthorized;
  var STATUS_CODES = _exports.STATUS_CODES = {
    error: 422,
    notFound: 404,
    ok: 200,
    created: 201,
    accepted: 202,
    noContent: 204,
    unauthorized: 401
  };
  var RESPONSE_ALIASES = _exports.RESPONSE_ALIASES = {
    ok: ['success']
  };
  var jsonMimeType = {
    "Content-Type": "application/json"
  };
  function error(status, errors) {
    if (!errors) {
      errors = status;
      status = STATUS_CODES.error;
    }
    return [status, jsonMimeType, errors];
  }
  function notFound(status, json) {
    if (!json) {
      json = status || {};
      status = STATUS_CODES.notFound;
    }
    return [status, jsonMimeType, json];
  }
  function ok(status, json) {
    if (!json) {
      json = status;
      status = STATUS_CODES.ok;
    }
    return [status, jsonMimeType, json];
  }
  function created(status, json) {
    if (!json) {
      json = status;
      status = STATUS_CODES.created;
    }
    return [status, jsonMimeType, json];
  }
  function accepted(status, json) {
    if (!json) {
      json = status;
      status = STATUS_CODES.accepted;
    }
    return [status, jsonMimeType, json];
  }
  function noContent(status) {
    if (!status) {
      status = STATUS_CODES.noContent;
    }
    return [status, jsonMimeType, ''];
  }
  function unauthorized(status) {
    if (!status) {
      status = STATUS_CODES.unauthorized;
    }
    return [status, jsonMimeType, ''];
  }
});