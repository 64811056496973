define("ember-cli-fake-server/lib/logging", ["exports", "ember-cli-fake-server/lib/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.handledRequest = handledRequest;
  _exports.unhandledRequest = unhandledRequest;
  function unhandledRequest(verb, path, request) {
    var msg = "[FakeServer] received unhandled request for ".concat(verb, " ").concat(path);
    (0, _logger.error)(msg, request);
    throw new Error(msg);
  }
  function handledRequest(verb, path) {
    (0, _logger.log)("[FakeServer] handled: ".concat(verb, " ").concat(path));
  }
});