define("ember-simple-auth/utils/is-fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFastBootCPM;
  _exports.isFastBoot = isFastBoot;
  /**
   * @return {ComputedProperty<boolean>}
   */
  function isFastBootCPM() {
    return Ember.computed(function () {
      return isFastBoot(Ember.getOwner(this));
    });
  }

  /**
   *
   * @param {ApplicationInstance} owner
   * @return {boolean}
   */
  function isFastBoot(owner) {
    (true && !(owner && typeof owner.lookup === 'function') && Ember.assert('You may only use isFastBoot() on a container-aware object', owner && typeof owner.lookup === 'function'));
    var fastboot = owner.lookup('service:fastboot');
    return fastboot ? fastboot.get('isFastBoot') : false;
  }
});